$('iframe').each(function(){
	let p = $(this).parents('.embed-responsive');
	let f = $(this).parents('.fb-page');
	let w = $(this).width() || 16;
	let h = $(this).height() || 9;
	let s = ((h / w) * 100);

	if(p.length === 0 || f.length === 0) {
		$(this).wrapAll(`<div class="embed-responsive" style="padding-bottom: ${s}%"></div>`);
	}
});