var goToTop = {
	btn: $('#irParaOTopo'),
	verifyed: false
};

goToTop.verify = function(){
	if($(window).scrollTop() >= 250) {
		goToTop.btn.addClass('active');
	}else{
		goToTop.btn.removeClass('active');
	}
};

goToTop.autoplay = (function(){
	goToTop.verify();

	goToTop.btn.click(function(){
		$(this).blur();

		$('html,body').animate({
			scrollTop : 0
		}, 1000);
	});

	$(window).scroll(function(){
		if(goToTop.verifyed) return;

		setTimeout(function(){
			goToTop.verifyed = false;
		}, 100);

		goToTop.verify();
	});
})();