$('.md-form').each(function(){
	let $this = $(this);
	let input = $(this).find('.form-control');
	let verify = function(input){
		if(input.val() != ''){
			$this.addClass('focus');
		}else{
			$this.removeClass('focus');
		}
	}

	verify(input);

	input.blur(function(){
		verify($(this));
	});

	input.focus(function(){
		$this.addClass('focus');
	})

});
